import { ToastsStore } from 'react-toasts'
import * as Strings from 'common/strings'

export function isValueEmpty (value) {
  return !value || value.trim() === ''
}

export function validateEmail (email) {
  if (isValueEmpty(email)) {
    ToastsStore.info(Strings.emailMissing, 3000, 'burntToast')
    return false
  }

  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
    ToastsStore.info(Strings.emailInvalid, 3000, 'burntToast')
    return false
  }

  return true
}

export function validatePassword (password) {
  if (isValueEmpty(password)) {
    ToastsStore.info(Strings.passwordMissing, 3000, 'burntToast')
    return false
  }

  return true
}

export function validateName (name) {
  if (isValueEmpty(name)) {
    ToastsStore.info(Strings.nameMissing, 3000, 'burntToast')
    return false
  }

  return true
}

export function validatePasswordConfirmation (password, confirmPassword) {
  if (isValueEmpty(confirmPassword)) {
    ToastsStore.info(Strings.confirmPasswordMissing, 3000, 'burntToast')
    return false
  }

  if (password !== confirmPassword) {
    ToastsStore.info(Strings.passwordsDoNotMatch, 3000, 'burntToast')
    return false
  }

  return true
}
